import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CenterWrap = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  h1 {
    margin-bottom: 2rem;
  }
`

const MedicalDisclaimerPage = () => (
  <Layout>
    <Seo title="Medical Disclaimer" />
    <CenterWrap>
      <h1>Medical Disclaimer</h1>
      <p>
        Any and all information found within our Website is for general
        educational and informational purposes only. This information is not
        intended, nor otherwise implied to be medical advice. Medical advice can
        only be offered to an established patient. To become an established
        patient, you must be seen in a face to face consult in which a medical
        assessment and examination can be done and a medical treatment plan
        discussed.
      </p>
      <p>
        The information found on this website is by no means complete or
        exhaustive and as such, does not encompass all conditions, disorders,
        health-related issues, or respective treatments. You should always
        consult a physician or other healthcare provider to determine the
        appropriateness of this information for your own situation.
      </p>
    </CenterWrap>
  </Layout>
)

export default MedicalDisclaimerPage
